export const name = "Relationship Expansion Pack"
export const menu = "Relationship"
export const edition = "Relationship Expansion Pack"
export const suggestedPlayer = "2 players"
export const color = {
    primary: {
        main:  '#c10016',
        contrastText: '#ffffff',
    },
    secondary: {
        main:  '#c10016',
    }
}
export const levels = ['Level 1 Perception', 'Level 2 Connection', 'Level 3 Reflection']
export const isExpansion = true

export const backDesc = [
    "What's more romantic\n than being understood?"
]

export const instruction = [
    "How well do you know your partner? How well do they know you?",
    "Communication creates partnership, but that requires sharing your truth and being open to receiving theirs. Especially when it's uncomfortable.",
    "This relationship expansion pack can be added to the original WNRS card game. Remember there are two ways to play this game. Either play safe, or play to grow. The second is how you win.",
    "Ready?"
]

export const level1 = [ // 6 cards
    "How important do you think\n birthdays and holidays are\n to me? Explain.",
    "On a scale of 1-10, how open do\n you feel I am with you? Explain.",
    "Who do you think was\n more nervous on our first\n date? Explain.",
    "What was the first thing\n you noticed about me?",
    "What do you think my\n perfect date night\n would be?",
    "What assumption did you\n make about me that turned\n out to be false?"
]

export const level2 = [ // 39 cards
    "What did our worst\n argument teach you?",
    "What has this relationship\n taught you about yourself?",
    "What's one small way I can\n be a better partner?",
    "What do you wish we did more of?",
    "What have you been extra\n sensitive to lately?",
    "What's the most important\n lesson a past relationship\n has taught you?",
    "What are you currently\n working through that I\n don't see, if anything?",
    "Have I helped you change\n your mind about anything?",
    "How do I show that I love\n you without telling you?",
    "What's one small way we\n can improve our sex life?",
    "What feelings are hard for\n you to communicate to me?\n How can I make it easier?",
    "What about you feels\n hardest to love?",
    "How have you seen me grow\n in this relationship?",
    "What have I helped you\n appreciate about yourself?",
    "What recent experience\n made you feel closer to me?",
    "What immediately attracted\n you to me? What attracted\n you more over time?",
    "What fear do you think\n holds me back the most?",
    "What song\n best describes\n our relationship?",
    "How does our age difference,\n or lack thereof, affect us?\n If at all?",
    "What's the most\n attractive thing I do\n without realizing it?",
    "What's the most\n romantic thing I've done\n for you recently?",
    "How does one earn your\n trust? Have I earned it?\n How can I earn more?",
    "Finish the sentence: Thank\n you for accepting _________.",
    "What would a day of\n completely spoiling me\n look like?",
    "What made you fall\n in love with me?",
    "Do you think my job affects\n me positively or negatively?\n How does it affect us?",
    "Are there any insecurities\n from previous relationships\n that you carried into this\n one? If so, what are they?",
    "When was the last time I hurt\n you, perhaps unintentionally?",
    "What's the best gift\n I've given you? Material\n and immaterial?",
    "The hardest thing for me\n to reveal about myself\n to you was _________.",
    "How do our strengths and\n weaknesses complement\n each other?",

    "Wild Card Write a love song for\n your partner. 1 minute.\n Perform for each other.",
    "Wild Card Write down the top 3 things\n your partner does that\n makes you feel most loved.\n Both players. Compare.",
    "Wild Card On a scale of 1-10 write down the\n importance of having kids to you.\n Compare and explain.",
    "Wild Card Write down one small way\n you can be better in this\n relationship. Both players. Compare.",
    "Wild Card Draw one of your favorite\n memories from our relationship.\n 30 seconds. Compare.",
    "Wild Card Write down the title you\n would give this chapter of\n our relationship. Compare.",
    "Wild Card Ask your partner something\n you've never asked them before.",
    "Wild Card Write a short guide of how\n to love your partner well.\n 30 seconds. Compare.",
]

export const level3 = [ // 9 cards
    "What answer of mine\n surprised you the most\n throughout this game,\n if any?",
    "Is there anything you\n still don't know about me\n that you wish you did?",
    "Thank you for helping me\n realize _________ about myself.",
    "What don't I give myself\n enough credit for?",
    "What goal would feel best for\n you to accomplish this year? How\n can I support you in that?",
    "What about our relationship\n are you proudest of?",
    "What did this conversation teach\n you about our relationship? What\n did it teach you about yourself?",

    "Wild Card Write down your intention for\n our relationship. Compare.",
    "Wild Card Write down the top 3 things\n you're most grateful for in\n your partner. 30 seconds.\n Compare.",
]