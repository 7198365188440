module.exports = {
    main:             require('./main'),
    honestDating:     require('./honestDating'),
    relationship:     require('./relationship'),
    innerCircle:      require('./innerCircle'),
    ownIt:            require('./ownIt'),
    breakup:          require('./breakup'),
    selfReflection:   require('./selfReflection'),
    selfLove:         require('./selfLove'),
    healing:          require('./healing'),
    quarantine:       require('./quarantine'),
    raceAndPrivilege: require('./raceAndPrivilege'),
    voting:           require('./voting'),
    bumbleDate:       require('./bumbleDate'),
    bumbleBFF:        require('./bumbleBFF'),
    bumbleBizz:       require('./bumbleBizz'),
    valentino:        require('./valentino'),
    bigTalk:          require('./bigTalk'),
    questions:        require('./36questions'),
    couples:        require('./couplesEdition'),
}