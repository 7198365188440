export const name = "Couples Edition"
export const menu = "Couples Edition"
export const edition = "Couples Edition (WNRS, Incomplete) and misc Couples Questions"
export const suggestedPlayer = "2 players"
export const color = {
    primary: {
        main:  '#c10016',
        contrastText: '#ffffff',
    },
    secondary: {
        main:  '#c10016',
    }
}
export const levels = ['Couples Edition WNRS', 'Couples Questions Quizlet']
export const isExpansion = false


export const backDesc = [
]

export const instruction = [
  "One deck is WNRS (incomplete) scraped from Quizlet",
  "The other deck is random couples questions found on Quizlet (not WNRS)"
]

export const level1 = [
  "what's the pain in me you'd most like to heal?",
"what are you hesitant to tell me?",
"what do you think is the next step in our relationship?",
"what do you think i'm learning from you?",
"why do you love me?",
"what is preventing me from becoming the person i want to be?",
"what's one thing you never want me to forget?",
"when was the last time i surprised you and why?",
"how will we raise our kids?",
"do you trust me?",
"when do you feel closest to me?",
"if i had an opportunity to work in another country, would you come with me?",
"what's your greatest fear of getting married?",
"do you ever get jealous of my success?",
"when was the last time i surprised you and why?",
"who takes more responsibility in our relationship?",
"what's been the greatest struggle in raising our kids?",
"who loves whom more in this relationship?",
"when's the last time you felt suffocated by this relationship and why?",
"when was our toughest moment together?",
"am i the best sex you've ever had?",
"in the back of your mind, are you scared i'll walk away?",
"what do you think i'm teaching you?",
"which of my insecurities drives you crazy?",
"how do you feel about coming second to our children?",
"if we get married, would you sign a prenup? why or why not?",
"what would make you leave me?",
"what do you think is the sexiest part of my body?",
"did you ever want children with me?",
"what do you think is holding me back?",
"what do you get from porn that you don't get from me?",
"which of my friends do you like the least?",
"do i still turn you on? why?",
"what do you think is the hardest thing for me?",
"do you really feel like we're growing apart?",
"would you want me to stay home if we had a child?",
"if you had to call me by another name, what would it be?",
"what do you think i'm missing out on by being with you?",
"how do you describe me to others?",
"what's been the greatest struggle in raising our kids?",
"why do you think i don't make enough money?",
"do i still turn you on? when?",
"do you love me? how have your feelings for me changed?",
"when was our toughest moment together?",
"what would you do if i cheated on you?",
"what's the wisest thing i taught you?",
"why are we making it work right now?",
"when was the last time i pissed you off when i wasted money?",
"how do you feel pressure from my family?",
"what is/was the biggest surprise about married life?",
"if you could change one thing about me to improve our relationship, what would it be and why?",
"where do you think i waste money?",
"when was the last time i made you feel secure as a man/woman?",
"what kind of family do you want to have with me?",
"name the top 5 things you love about me.",
"do you really feel that you are pushing yourself hard enough or are you settling?",
"who sacrifices more in our relationship and how do you feel about that?",
"what's your favorite imperfection about me?",
"when do you worry about me and why?",
"describe what you were like before we met.",
"what's one piece of clothing of mine you wish you could burn and why?",
"what can i do to be better for you?",
"describe what you were like before we met?",
"describe one experience you wish we'd hav in the future.",
"what's your favorite memory from your worst relationship?",
"when you think of our family, what is the first image that comes to your mind?",
"what's one thing i could do that would surprise you",
"what in our relationships are you most grateful?",
"if we met 10 years ago, how do you think we would relate?",
"how am i most similar and different to people you've dated?",
"what's something we may disagree on in the future?",
"what's one thing i could do that would surprise you?",
"what do you think i have yet to learn about myself?",
"what is my blind spot?",
"what do you think i'm concerned about when it comes to our relationship?",
"what do you think is my super power?",
"when do you think i need space and are you giving it to me?",
"when you look at me, what do you see?",
"what do you think i need and are you giving it to me?",
"what do you see in me that i don't see for myself?",
"when was the last tie you felt insecure and how did you handle it?",
"how do you think you can be healing for me?",
"do you think our relationship is more truth or dare? how do you feel about it?",
"what do you think turns me on that i'm afraid to admit?",
"when thinking of us together, what are your hesitations?",
"what's something you'd really like to experience together, but know we cant? why?",
"how do you think i want to be loved?",
"what experience shaped your perspective on relationships?",
]

export const level2 = [ // 31 cards
  "todo"
]