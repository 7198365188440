export const name = "Big Talk"
export const menu = "Big Talk"
export const suggestedPlayer = "2-6 players"
export const color = {
  primary: {
    main:  '#c10016',
    contrastText: '#ffffff',
  },
  secondary: {
    main:  '#c10016',
  }
}
export const levels = ['Original', 'Love', 'Kids', 'Work', 'Self', 'Travel']
export const isExpansion = false

export const instruction = [
  "Not WNRS. Big Talk card game.",
]

export const level1 = [
  "What is your next great adventure?",
  "What are you thankful for this very moment?",
  "What don’t you spend enough time doing?",
  "Describe a perfect day.",
  "What do you wish more people knew about you?",
  "Describe a turning point in your life.",
  "If money were no concern, how would you lead life differently?",
  "What little things in life do you take the time to stop and appreciate?",
  "How do you show love?",
  "What can you do today that you couldn’t do a year ago?",
  "What was your childhood dream? Has it changed?",
  "What makes you feel really alive?",
  "What is a quirk your family has?",
  "What do you miss?",
  "Where would you like to wake up tomorrow?",
  "What are you looking forward to?",
  "What object would you save if your house was on fire?",
  "Do you want your children (if you have any) to be like you?",
  "Is there anything you wish you didn’t know?",
  "What are you most grateful for?",
  "If you had 24 hours left to live, who would you be with in 23 hours?",
  "What was the best compliment you ever received?",
  "What is one thing that could happen today to make it even better?",
  "Is there anybody in your life you admire? Why?",
  "What should people do more of?",
  "How would your friends describe you in a few sentences?",
  "If you could create and run your own country, what would it be like?",
  "If you could talk to everyone in the world, what would you say?",
  "What do you wish you knew?",
  "What gives you goose bumps?",
  "What do you want to do before you die?",
  "What makes you lose track of time?",
  "What do you fight for?",
  "What is your number one priority today?",
  "What advice would you ask for from your greatest hero?",
  "What has been your favorite age so far and why?",
  "What advice would you give yourself five years ago?",
  "What do you value that doesn’t cost money?",
  "Who in this world do you love most and what are you doing about it?",
  "What do you spend too much time doing?",
  "What are you curious about lately?",
  "What was the most impactful event in your life?",
  "Do you fit in or stand out? How so?",
  "What’s your earliest memory of achievement?",
  "What piece of advice would you offer to a newborn infant?",
  "What does this world need more of?",
  "What is one question you would ask a fortuneteller?",
  "What non-physical traits do you find attractive in other human beings?",
  "Is there anyone who you would trust with your life?",
  "If you could freeze time, what would you do?",
  "What gives you hope?",
  "What is the most spontaneous thing you’ve ever done?",
  "What life lesson do you wish everyone was taught in school?",
  "What do you find beautiful?",
  "What were you doing the last time you lost track of time?",
  "How are you...really doing?",
  "What is your greatest strength?",
  "What do you hope to achieve in five years?",
  "What do you stand for?",
  "What is something new you have recently tried?",
  "What matters to you and why?",
  "What have you witnessed that has strengthened your faith in humanity?",
  "What is a new habit you want to form?",
  "How would you live life differently if you could never die?",
  "Why do you do what you do?",
  "What is a common misconception that people may have about you?",
  "For who or what do you work for?",
  "What makes you, you?",
  "What are the first things you notice when meeting someone?",
  "What is one of the kindest things someone has ever done for you?",
  "When was the last time you lied? Why?",
  "What does success mean to you?",
  "What do you have that you cannot live without?",
  "Describe a first in your life.",
  "Describe paradise",
  "What is the best gift that someone can give you?",
  "How do you feel about birthdays?",
  "Where do you find peace?",
  "What have you started but never finished and why?",
  "How are you making a difference in the world?",
  "What is your favorite place in the world?",
  "Have you ever had any near death experiences?",
  "What do you value most in a friendship?",
  "What do you value?",
  "What do you dream about?",
  "What is your relationship with your mother like?",
  "What do you think about when you wake up?",
  "What do you do when nobody is watching?",
  "What inspires you?",
  "Has a conversation ever changed your life?",
];

export const level2 = [
  "Describe the perfect date.",
  "What are your favorite qualities about your partner?",
  "What do you feel is the best way to show love without spending any money?",
  "What do you wish we did together more often?",
  "What makes you and your partner similar? What makes you stand apart?",
  "Who do you look up to when it comes to leading a great relationship? Why?",
  "What is your love language?",
  "What's been on your mind about love, romance, and relationships lately?",
  "What does it take to fall in love?",
  "What do you look forward to experiencing in a romantic relationship?",
  "How have your values changed over time?",
  "What lessons have you learned from previous relationships?",
  "Describe your dream vacation.",
  "What helps you relax when you are overwhelmed?",
  "What was your first heartbreak like?",
  "What made you fall for your first crush?",
  "What is your definition of love?",
  "What puts you most in the flow?",
  "What have you always wanted to ask me?",
  "What makes your heart hurt?",
  "What is the bravest thing you have ever done?",
  "What do you hope for in five years in your romantic life?",
  "What was your first impression of me?",
  "What do you wish people knew about you?",
  "In what ways do you think you are misunderstood?",
  "What makes you laugh?",
  "What are you like at your best and worst?",
  "What do you find physically attractive about your partner?",
  "What, if anything, are you embarassed to tell your partner?",
  "What compromises have you made for relationships in the past?",
  "What are you willing to sacrifice for love?",
  "What do you believe makes a relationship last?",
  "What's on your bucket list with a partner?",
  "What are your most cherished memories with your partner?",
  "What kind of parent do you think you would be?",
  "What is your favorite relationship advice you have received?",
  "How would you like to change or grow in the next decade?",
];

export const level3 = [
  "What advice do you have for adults?",
  "What makes you happy?",
  "What animal are you and why?",
  "What do other people not know about you?",
  "If you could start a new planet, what would it be like?",
  "What do you dream about?",
  "What do you enjoy helping others with?",
  "What are you thankful for?",
  "If you could invent a rule what would it be?",
  "What does your dream home have?",
  "If you could go anywhere in the world where would you go?",
  "What do you think exists in outer space?",
  "What makes you smile or laugh?",
  "What are your favorite memories?",
  "How do you show you care to other people?",
  "What are you proud of?",
  "What is your biggest fear? How can we help overcome it?",
  "If you could change anything in the world what would it be?",
  "What do you hope to be when you grow up?",
  "What are you excited about?",
  "What are your strengths and weaknesses?",
  "What do you love to do more than anything?",
  "How would you describe yourself in five words?",
  "What is a mistake you once made?",
  "What are you excited to learn about?",
  "What are you looking forward to about growing up?",
  "If you could be president one day, what would you do?",
  "What do you wish for?",
  "If you had a million dollars, how would you spend it?",
  "Who do you want to make friends with or meet?",
  "How would you describe your family?",
  "Who do you admire or look up to and why?",
  "What is something hard or sad for you in your life?",
  "What do you wish your parents/caretakers would do more of?",
  "When do you feel most loved and safest?",
];

export const level4 = [
  "What are the favorite jobs you have held and why?",
  "What drains you? How can you rid yourself of those things?",
  "If you could start your own company / movement / charity, what would it be?",
  'If you were to tell one person "Thank You" for helping me become the person I am today, who would it be and what did they do?',
  "What's the most important lesson you've learned in the last year?",
  "When have you felt most successful?",
  "What do you excel at in work? How might you create more opportunities to do those things?",
  "What do you struggle with in work? How might you find help with those things?",
  "What is your dream role?",
  "When do you feel most purposeful while working?",
  "What are your least favorite jobs you have held and why?",
  "Describe a time you made a mistake, and what you learned from it. ",
  "What do you need help with at work?",
  "How do you like to start your day?",
  "How do you like to end your day?",
  "What energizes you? How can you introduce more of those things into your life?",
  "What is an accomplishment you are proud of?",
  "What don't you give yourself enough credit for?",
  "What’s the biggest misconception people have about your position?",
  "What do you do when you're having a bad day?",
  "What advice would you give to yourself when you first started working?",
  "Who is your role model?",
  "What parts of your work self are very different from your personal self?",
  "What can other people learn from you?",
  "What is your secret talent that most people don't know about?",
  "What do you most enjoy spending your money on?",
  "What made you smile recently?",
  "How would you describe your job to a child?",
  "What's the coolest thing you have ever worked on?",
  "What would you be doing if you weren't at your current role?",
  "If you were to start a company from scratch, what values would you build it on?",
  "What characteristic do you most admire in others?",
  "What would you most regret not having done by the end of your life?",
  "What kind of impact do you believe you have on people?",
  "When working, what puts you most in the flow?",
  "What would make you most excited to wake up every morning?",
];

export const level5 = [
  "What am I most afraid of and what's it stopping me from doing?",
  "What do I hope others will remember about me at the end of my life?",
  "What do I miss from childhood and how can I bring that back into my life?",
  "Make a list of the top people in your life you trust and can turn to in times of need.",
  "If I could do just three things this week, what would they be?",
  "Who am I, really? Is there a difference between who I am to others versus myself?",
  "What am I most worried about for the future? Why?",
  "What matters most to me?",
  "What recent memories will I cherish the most?",
  "What can I do to make others smile?",
  "What would I like to change about myself?",
  "The moments of my life I will never forget include...",
  "When do I feel most playful or fun?",
  "What are the words or values I live by?",
  "What books / shows / music / culture do I want to learn more about?",
  "I really wish others knew ___ about me.",
  "What do I love about life?",
  "What do I struggle with in life? How can I work on it?",
  "The top life lessons I have learned recently include:",
  "The life lesson I am still in the process of learning is:",
  "What are the words I need to hear?",
  "If I could do just three things this year, what would they be?",
  "If I could do just three things in my life, what would they be?",
  "Where can I perform more acts of kindness?",
  "What brings me pure peace and joy?",
  "What new habits do I want to form and reinforce?",
  "Who can I help?",
  "What inspires me?",
  "Where can I create more serendipity in my life?",
  "What am I looking forward to in the near future?",
  "When do I feel most myself?",
  "What have I always wanted to do that now would be a great time to pursue?",
  "What are ten things I am grateful for?",
  "What one thing could make today great?",
  "What do I need to let go of to be happier?",
  "What are five things I love about myself?",
];

export const level6 = [
  "Where do you dream to visit and why?",
  "What are your most unusual travel experiences?",
  "What kind of person are you while traveling versus when at home?",
  "Who or what do you miss from home when you are traveling?",
  "What does home mean to you?",
  "If you could take a long summer road trip, where would you go?",
  "What are your favorite memories from traveling as a kid?",
  "If you had an unlimited budget to plan a trip, where would you go and what would you do?",
  "Has a stranger ever inspired you while you were traveling without them knowing it?",
  "What are the most beautiful sights you have seen?",
  "If you had to live in a different country, where would you live and why?",
  "What are the first things you do when you get home from a trip?",
  "What local experiences do you seek when you visit a new place?",
  "Have you ever made any mistakes while traveling? What would you do differently?",
  "When do you feel most adventurous?",
  "What's the scariest thing you have done?",
  "What are the weirdest places you have ever slept?",
  "How do you reflect upon your travel experiences?",
  "What motivates you to travel?",
  "What souvenirs do you usually seek on your travels?",
  "What are the best things money can't buy when you travel?",
  "How would you describe your travel style?",
  "What are your earliest travel memories?",
  "What have you learned from traveling?",
  "Have you ever traveled by yourself? What did you experience / learn?",
  "What makes a great travel companion?",
  "Where do you want to visit before you die?",
  "What adventure movies / books / shows / people throughout history inspire you?",
  "If you were stuck on a deserted island and could bring five nonessential things what would they be?",
];