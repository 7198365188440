export const name = "Honest Dating Expansion Pack"
export const menu = "Honest Dating"
export const edition = "Honest Dating Expansion Pack"
export const suggestedPlayer = "2 players"
export const color = {
    primary: {
        main:  '#c10016',
        contrastText: '#ffffff',
    },
    secondary: {
        main:  '#c10016',
    }
}
export const levels = ['Level 1 Perception', 'Level 2 Connection', 'Level 3 Reflection']
export const isExpansion = true


export const backDesc = [
    "What if dating was\n as simple as getting to know\n another human being?",
    "No games, just this one."
]

export const instruction = [
    "How honest are your dating experiences, really?",
    "Dating doesn't have to be a game, it can be as simple as getting to know another human being. Discovering compatibility with another person is only possible when we show up as our true selves, but we have to be willing to let our true selves be seen.",
    "This dating explansion pack can be added to the original WNRS card game. Each player alternates between asking and answering each question. How do you win? Stay honest.",
    "Ready?"
]

export const level1 = [ // 8 cards
    "Just by looking at me,\n what would you think\n I do for a living?",
    "What do you think my type\n is? Describe in detail.",
    "What do you think my\n drink of choice is?",
    "How many relationship do\n you think I've been in? How\n many times do you think I've\n been in love? Explain.",
    "What do you think I was like\n in high school? Explain.",
    "Do you think I'm still on\n good terms with my\n exes? Why or why not?",
    "What was the first thing\n you noticed about me when\n we met in person?",

    "Wild Card Write down your 3 favorite\n physical features of your\n partner and read them out\n loud. Both players.",
]

export const level2 = [ // 31 cards
    "What are you feeling\n a lot lately?",
    "Do you have any\n unconventional opinions\n when it comes to romantic\n relationships?",
    "I know I really like\n someone when _________.",
    "Has sex ever been a\n deal breaker in your\n past relationships?\n Explain.",
    "When's the last time you\n ghosted someone and why?",
    "What about me intrigued\n you besides my physical\n appearance?",
    "What do your friends know\n about me, if anything?",
    "What part of your job\n energizes you the most?\n Leaves you the most\n drained?",
    "How has your view on love\n evolved over time, if at all?",
    "How do you define \"Dating\"?",
    "Describe your worst\n heartbreak. What did\n it teach you?",
    "Have you ever considered\n an open relationship?\n Why or why not?",
    "Who in your life do you feel\n most yourself around? Why?",
    "Which one of your parents'\n personality traits do you\n want to keep? Let go of?",
    "What is something new\n you've learned about\n yourself recently?",
    "What's the hardest\n part about dating you?",
    "What are you overthinking\n right now?",
    "What have you tolerated\n from others in the past\n that you no longer have\n space for?",
    "What dating advice would you\n give your younger self?",
    "How emotionally available do\n you currently feel? Explain.",
    "What has being\n single taught you\n about yourself?",
    "What was the worst\n date you've ever been\n on and why?",
    "What about you has felt\n repeatedly misunderstood\n by others, if anything?",
    "What's a\n non-negotiable\n in your life?",
    "What did the people who raised\n you teach you about love? How\n has that shaped your views on\n love today?",

    "Wild Card Write down your top 2\n relationship deal breakers.\n Both players. Compare.",
    "Wild Card Write down your favorite\n song lyric you can think of\n off the top of your head.\n Compare and explain.",
    "Wild Card Share something you\n typically wouldn't on a\n first date. Both players.",
    "Wild Card Give each other tattoos\n with the nearest pen or\n marker. 30 seconds.",
    "Wild Card Write down the top 3 most\n important qualities in a romantic\n partner. Both players. Compare.",
    "Wild Card Think of the most underrated\n show streaming currently. On the\n count of 3, say your answers out\n loud. Both players.",
]

export const level3 = [ // 11 cards
    "What song should I listen\n to on my way home?",
    "Based on what you've learned\n about me, what do you think I'm\n looking for romantically?",
    "On a scale of 1-10 how\n \"Emotionally available\"\n do I seem? Explain.",
    "What mannerisms of\n mine stood out the\n most to you, if any?",
    "How would you describe\n our date in one word?",
    "If you were to write\n my dating profile bio,\n what would it say?",
    "What part of my personality\n isn't showcased online?",
    "What do you find most\n attractive about me that\n isn't physical?",

    "Wild Card Look through each other's\n social media. Pick your favorite\n photo. Compare and explain.",
    "Wild Card Write your partner a text outside\n of your comfort zone at this end\n of this date. Both players.",
    "Wild Card Ask literally anything.\n Permission to go there.",
]