export const name = "WNRS x DVF Own It Expansion Pack"
export const menu = "Own It"
export const crossover = "DVF"
export const suggestedPlayer = "2+ players"
export const color = {
    primary: {
        main:  '#c10016',
        contrastText: '#ffffff',
    },
    secondary: {
        main: '#181818'
    },
}
/*
    Yellow: #d0af3a
    Orange: #c66532
    Red:    #af2637
    Green:  #275835
    Blue:   #5f86b5
    Pink:   #c97986
*/
export const levels = ['Own It']
export const isExpansion = true

export const backDesc = [
    "Own It Expansion Pack"
]

export const instruction = [
    "What if your biggest insecurities were your greatest strengths? If you own it. They are!",
    "To \"own it\" is to accept the truth and deal with it, however unpleasant it may be. We're Not Really Strangers and DVF came together to create an expansion pack all about owning the person we are today and the person we are becoming.",
    "To play, journal your answers or explore this deck with a loved one who's willing to dig deep.",
    "Owning who we are is the best thing we can do for ourselves and the people in our lives.",
    "Ready?"
]

export const level1 = [
    "When was the last time you felt complete [#af2637](zen)? Who were you with? Where were you? What were you doing?",
    "How would you describe\n your relationship with [#d0af3a](yourself)\n in one word?", //
    "What is your [#275835](x-factor)?\n Permission to brag.",
    "Who is a [#c66532](woman) in your life that inspires you to be a better person? Why does she come to mind? (Send her a thank you)",
    "What still feels [#c97986](vulnerable) in your life? What does owning it look like for you?",
    "Who from your past would you want to [#5f86b5](reunite) with? What would you ask them?",
    "What's the hardest [#275835](truth)\n you've had to own\n this past year? What has\n owning it taught you?", //
    "How has your definition of [#d0af3a](success) evolved over time? What is your definition of it today? Get specific.",
    "Who in your life do you want to build a deeper [#af2637](relationship) with? (Be direct and tell them)",
    "What [#5f86b5](question) have you\n avoided asking someone? What\n question have you avoided\n asking yourself?", //
    "What area of your life have you made the most [#c97986](progress) in recently? Get specific. Take a moment to congratulate yourself.",
    "What part(s) of yourself\n have you been minimizing that\n you're ready to finally own?\n Permission to [#c97986](own it).", //
    "Who do you feel [#d0af3a](misunderstood) by? What do you wish they would come to understand?",
    "What lesson in [#275835](love)\n took you the longest\n to learn?", //
    "What act of [#c66532](kindness)\n from a stranger will\n you never forget?", //
    "What has brought you the most unexpected [#c97986](joy) recently?",
    "What has your [#af2637](heart)\n been telling you that\n you've been ignoring?", //
    "What have you not [#d0af3a](forgiven) that hurts you the most?",
    "What have you [#c66532](envied)\n in the past that you\n can laugh at now?", //
    "What [#c97986](door) are you most\n grateful for closing that\n felt like the end of\n the world at the time? What\n did it open for you?", //
    "What do you wish\n you had the [#af2637](courage)\n to do? To say?", //
    "What [#5f86b5](blame) have you been placing on someone else that you can take some accountability for?",
    "What have you become\n newly [#d0af3a](aware) of within\n yourself recently?\n Get specific.", //

    "Wild Card [#5f86b5](Set an intention for\n today, this month and\n this year. Write it out.)",
    "Wild Card [#af2637](Spend at least 5 minutes in nature today. Be still and observe. What did you notice?)",
    "Wild Card [#275835](Write down 3 things\n you're most grateful for\n in this present moment.\n Big or small.)", //

]